.course-name {
  font-size: 1.1rem;
  font-weight: bold;
}

.course-category {
  font-size: 0.85rem;
  font-style: italic;
}

.class-image {
  width: 200px;
  height: 200px;
  margin: 15px 0 0 -70px;
}

.course-list-container {
  text-align: left;
  max-height: 600px;
  overflow-y: scroll;
  border: 1px solid #ccc;
  padding: 10px 5px;
}

.no-sections {
  background-color: #ddd;
}

.no-sections-this-cycle {font-size: 0.85em;}

.checkbox-adjust-position {padding-top: 7px;}
.course-disabled {color: red !important;}
.badge-spacer {margin-right: 5px;}
.badge-request-size {width: 42px;}
