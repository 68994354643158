.intervention-complete {
  background-color: green;
  color: white;
}

.intervention-incomplete {
  background-color: red;
  color: white;
}

.intervention {
  height: 25px;
  width: 50px;
  border-radius: 12px;
  margin: 3px auto 0 auto;
  display: inline-table;
}

.change-in-process {
  background-color: #aaaaaa !important;
  color: white;
}
.btn-intervention-sort {height: 10px;}
