.scheduler-navbar {
  margin: 15px 0 25px 0;
}

.scheduler-navbar .btn {
  width: 200px;
}

.enrolled {background-color: white;}
.not-enrolled {background-color: rgb(244,204,205);}
