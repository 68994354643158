.login-button {
  width: fit-content;
  margin: 0 auto 10px auto;
}

.gv {
  margin-top: 10px;
  border-top: 1px solid #aaa;
}
.gv div {
  margin-top: 15px;
}
.gv-title {font-weight: bold;}
.gv-header {font-weight: bold;}

.unable-to-login {font-size: 1.2em; font-weight: bold;}
