.course-name {
  font-size: 1.1rem;
  font-weight: bold;
}

.course-category {
  font-size: 0.85rem;
  font-style: italic;
}

.class-image {
  width: 200px;
  height: 200px;
  margin: 15px 0 0 -70px;
}

.course-list-container {
  text-align: left;
  max-height: 600px;
  overflow-y: scroll;
  border: 1px solid #ccc;
  padding: 10px 5px;
}

.no-sections {
  background-color: #ddd;
}

/*.requested {color: green;}*/
.active .requested {color: lightgreen !important;}
.course-image {width: 30%; margin-top: 10px;}
.item-header {background-color: lightgray !important;}
img {width: 50%}
.requested {border: 3px solid green !important;}
.course-card {cursor: pointer;}
.bold-header {font-weight: bold;}
.disclaimer-border {border-top: 2px solid #888; padding-top: 8px;}
.disclaimer {text-align: left;}
