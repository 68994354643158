.directions {
  text-align: left;
  font-size: 0.9rem;
}

.directions-header {
  font-weight: bold;
}

.directions-bullets {
}
