.scheduler-navbar {
  margin: 15px 0 25px 0;
}

.scheduler-navbar .btn {
  width: 200px;
}

.btn-enroll {
  margin: 5px 0;
}

.btn-unenroll {
}

.table-header {
  background-color: lightgray;
}

.update-in-process {opacity: 0.5}

.scheduler-year-container {
  height: 500px;
  overflow-y: scroll;
}

.scheduler-year-container table tbody tr td {
  padding-left: 10px;
}

.show-btn {
  height: 25px;
  background-color: #0d6efd;
  font-size: 0.85rem;
  border-radius: 5px;
  color: white;
}

.student-list-item {
  cursor: pointer;
}

.left-spacer {padding-left: 10px;}

.no-course-match {color: purple;}

.btn-block {width: 100%;}

.enrolled-course {color: blue; font-style: italic;}

.already-taken {font-weight: bold; color: orangered;}

.in-this-section {color: green;}

.btn-randomly-select {margin-left: 15px; height: 25px; padding: 0 10px !important;}

.readonly-choices {opacity: 0.6;}

.inactive-course {font-style: italic; background-color: #eee;}

.new-enrollment {}

.old-enrollment {background-color: #eee;}

.no-enrollment {background-color: rgb(244,204,205)}

.no-sections {background-color: #ddd !important; font-style: italic;}

.pointer {cursor: pointer;}

.col-header {font-weight: bold;}

.intervention-count {width: 18%;}
.intervention-teacher-name {font-size: 0.75rem;}
