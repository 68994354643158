.settings-button {
  width: 100%;
}

.settings-divider {
  border-bottom: 1px solid #ccc;
  padding-top: 5px;
}

.left-divider {
  border-left: 1px solid #ccc;
}

.true-button {
  background-color: green;
  color: white;
  width: 50px;
}

.false-button {
  background-color: red;
  color: white;
  width: 50px;
}

.font-bold {
  font-weight: bold;
}

.true-button-text,
.false-button-text {
  font-weight: bold;
}
