body {
  margin: 0;
  text-align: center;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

button {
  margin: 2px;
}

.spacer {
  margin-bottom: 10px;
}

.pointer {
  cursor: pointer;
}

.name-text {
  padding-top: 6px;
}

label {
  font-weight: bold;
}

.active-course,
.active-student {
  background-color: #0d6efd;
  color: white;
}

.enrolled {
  background-color: rgb(217, 233, 212);
  color: black;
}

.table-bordered-heavy {
  border: 1px solid #aaa;
}

.table-condensed {
  font-size: 0.85rem;
}

input[type="checkbox"] {margin-left: 10px;}
