.user-admin-tag {
  font-style: italic;
  color: blue;
}

.active .user-admin-tag {
  color: white;
}

.user-admin-switch {
  padding: 8px 0 0 52px;
  margin-left: 12px;
}

.true-button {
  background-color: green;
  color: white;
  width: 50px;
}

.false-button {
  background-color: red;
  color: white;
  width: 50px;
}

.font-bold {
  font-weight: bold;
}

.true-button-text,
.false-button-text {
  font-weight: bold;
}

.dupe-error {
  border-color: red !important;
}

.user-list {
  text-align: left;
  overflow-y: scroll;
  height: 500px;
}
