.card-header {
  font-size: 2rem;
  background-color: rgb(208, 226, 242) !important;
}

.app-navbar {
  margin: 15px 0 25px 0;
}

.app-navbar .btn {
  width: 115px;
}

.container-body {
  min-height: 400px;
}
