.toggle-button {
  height: 25px;
  margin: 0 0 4px 30px;
}
.intervention-summary {
  margin: 15px;
}
.intervention-scroller {
  height: 600px;
  overflow-y: scroll;
}
.intervention-table {
  font-size: 0.85rem;
}
.intervention-cell {
  cursor: pointer;
}
.intervention-on {
  background-color: yellow !important;
  color: black;
}
.intervention {
  font-weight: bold;
  height: 25px;
  width: 50px;
}
.intervention-complete {
  color: green;
}
.intervention-incomplete {
  color: red;
}
