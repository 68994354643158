.shaded-header {
  background-color: #ddd;
}

.student-navbar {
  margin: 15px 0 25px 0;
}

.student-navbar .btn {
  width: 250px;
}

.student-enroll-list th {
  padding-left: 5px;
}

.student-enroll-list-id {
  width: 15%;
}

.student-enroll-list-grade {
  width: 10%;
}

.student-enroll-list-intervention {
  width: 5%;
}

.student-enroll-list-status {
  width: 8%;
}

.non-block {
  display: table-cell;
}

.wider {
  width: 75px;
}

.small-header {
  font-size: 0.85em;
}

.in-process-student {
  background-color: #aaaaaa !important;
  color: white;
}

.enrolled {background-color: rgb(217, 233,212)!important;}
.active.no-sections {color: blue;}
